$(document).ready(function() {
  const config = window.nc_companygusfetcher;

  if (config.displayMode !== 'custom') {
    $('input[name=vat_number]')
      .wrap(`<div class="${config.fieldWrapper.class}"></div>`)
      .parent(`.${config.fieldWrapper.class}`)
      .append(`<div class="${config.buttonWrapper.class}"><button type="button" class="${config.button.class}" data-action="nc-companygusfetcher-fetch" data-url="${config.ajaxUrl}">${config.button.label}</button></div>`);
  }

  $('[data-action="nc-companygusfetcher-fetch"]').on('click', async function (e) {
    e.preventDefault();

    const searchParams = new URLSearchParams();
    searchParams.append('token', window.prestashop.static_token);
    searchParams.append('nip', $('[name="vat_number"]').val());

    const response = await fetch($(this).data('url') + '?' + searchParams.toString());
    const data = await response.json();

    $('[name="company"]').val(data.name);
    $('[name="address1"]').val(((data.street || '') + ' ' + (data.propertyNumber || '') + ' ' + (data.apartmentNumber || '')).trim());
    $('[name="city"]').val(data.city);
    $('[name="postcode"]').val(data.zipCode);
  });
});
